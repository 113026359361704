import { Picture } from "./Picture";

export function PhotoGallery() {
    return (
        <div className="flex flex-col p-8 bg-light-brown gap-4">
            <div className="flex flex-col text-center text-dark-brown gap-2">
                <p className="text-2xl">Consultório</p>
                <p className="text-xl">Localizado em <b>Osasco</b>, São Paulo</p>
            </div>
            <div className="flex flex-col gap-4 lg:flex-row lg:justify-center lg:gap-16">
                <Picture key="clinic-1" path="clinic-1.webp" alt="Foto do consultório da Dra. Daiane" blockCss="rounded-xl lg:h-[600px]" />
                <Picture key="clinic-2" path="clinic-2.webp" alt="Foto do consultório da Dra. Daiane" blockCss="rounded-xl lg:h-[600px]" />
                <Picture key="clinic-3" path="clinic-3.webp" alt="Foto do consultório da Dra. Daiane" blockCss="rounded-xl lg:h-[600px]" />
            </div>
        </div>
    )
}