import { Button } from "./Button";
import { Picture } from "./Picture";

export function CallToAction() {
    return (
        <div className="flex flex-col bg-light-brown text-center justify-center gap-4 lg:flex-row lg:items-center lg:max-h-[600px] py-4">
            <div className="flex flex-col gap-4 px-4">
                <p className="font-semibold text-2xl">Você não precisa passar por isso sozinho</p>
                <div className="flex flex-col gap-2">
                    <p className="italic">Vamos tratar seu reumatismo juntos?</p>
                    <div className="flex justify-center gap-8">
                        <Button id="whatsapp_contato_cta_osasco"
                            url="https://api.whatsapp.com/send?phone=5511976924730&text=Ol%C3%A1!%20Gostaria%20de%20agendar%20uma%20consulta%20com%20a%20Dra.%20Daiane!"
                            text="Osasco"
                        />
                        <Button id="whatsapp_contato_cta_peruibe"
                            url="https://api.whatsapp.com/send?phone=551334517000&text=Ol%C3%A1!%20Gostaria%20de%20agendar%20uma%20consulta%20com%20a%20Dra.%20Daiane!"
                            text="Peruíbe"
                        />
                    </div>
                </div>
            </div>
            <Picture path="dra-daiane-moraes-3.webp" alt="Dra. Daiane Moraes | Reumatologista" blockCss="rounded-xl lg:max-h-[600px] lg:py-8 px-8" />
        </div>
    )
}
