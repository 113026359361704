import { Button } from "./Button";
import { Picture } from "./Picture";

export function About() {
    return (
        <div className="flex flex-col bg-light-brown py-4 px-6 text-dark-brown lg:gap-10 lg:flex-row-reverse">
            <div className="flex flex-col text-center gap-8 lg:w-1/2 lg:justify-center lg:text-start lg:h-[600px]">
                <div className="flex flex-col gap-2">
                    <p className="text-3xl lg:text-5xl">Sobre mim</p>
                    <p className="text-xl lg:text-2xl"><b>- CRM</b> 139928 <b>- RQE</b> 50163</p>
                </div>
                <div className="flex flex-col gap-2 lg:w-96">
                    <p className="">Sou Especialista em Reumatologia há mais de 7 anos e a minha missão é proporcionar um atendimento humanizado de alta qualidade aos meus pacientes.</p>
                    <p className="">Juntos podemos transformar a sua saúde e qualidade de vida!</p>
                </div>
                <div className="flex justify-center gap-8 lg:justify-start">
                    <Button id="instagram-button"
                        url="https://www.instagram.com/dradaianemoraes/"
                        icon="instagram"
                    />
                    <Button id="google-review-button"
                        url="https://g.page/r/CYXIufjMa9VPEAI/review"
                        icon="google"
                    />
                </div>
            </div>
            <div className="p-4 lg:w-1/2">
                <Picture blockCss="rounded-3xl lg:h-[600px] lg:justify-end" path="dra-daiane-moraes-2.webp" alt="Dra. Daiane Moraes | Reumatologista"/>
            </div>
        </div>
    )
}