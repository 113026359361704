export interface PictureProps {
    path: string
    alt: string
    blockCss?: string
}

export function Picture({ path, alt, blockCss }: PictureProps) {
    return (
        <img src={`images/${path}`} alt={alt} className={blockCss}></img>
    )
}